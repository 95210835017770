import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Nav = styled.nav`
  display: flex;
  padding: 2% 6%;
  justify-content: space-between;
  align-items: top;
  image-rendering: -webkit-optimize-contrast;

  img {
    width: 170px;
    height: 50px;
  }

  @media (max-width: 768px) {
    padding: 2% 4%;
  }

  @media (max-width: 480px) {
    padding: 2% 2%;
  }
`;

export const NavLink = styled.div`
  flex: 1;
  text-align: right;
  display: block;
  
  @media (max-width: 768px) {
    display:none;
  }

  
`;

export const NavLi = styled(Link)`
  list-style: none;
  display: inline-block;
  padding: 8px 12px;
  position: relative;
  color: ${props => (props.textColor ? props.textColor : '#ffffff')};
  text-decoration: none;
  font-size: 13px;

  &:after {
    content: '';
    width: 0%;
    height: 2px;
    background: #f44336;
    display: block;
    margin: auto;
    transition: 0.5s;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 6px 8px;

    &:after {
      height: 1px;
    }
  }

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 4px 6px;

    &:after {
      height: 1px;
    }
  }
`;

// New styles for mobile menu
export const HamburgerIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  display: none; /* Hide the hamburger icon by default */

  @media (max-width: 768px) {
    display: block; /* Show the hamburger icon on mobile screens */
    font-size: 20px;
    cursor: pointer;
    margin-top: 5px;
  }
`;

export const CloseIcon = styled.div`
  display: none; /* Hide the close (cross) icon by default */

  @media (max-width: 768px) {
    display: block; /* Show the close icon on mobile screens */
    font-size: 20px;
    cursor: pointer;
    margin-top: 5px;
  }
`;

export const MobileMenu = styled.div`
  display: none; /* Hide the mobile menu by default */
  margin-top: 120px;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: 768px) {
    display: block; /* Show the mobile menu on mobile screens */
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 999;
    text-align: center;
    padding: 10px 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding: 10px 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;
