import {
    FETCH_POSTS_REQUEST,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
    FETCH_POST_FAILURE,
    FETCH_POST_REQUEST,
    FETCH_POST_SUCCESS,
    FETCH_FPOSTS_FAILURE,
    FETCH_FPOSTS_REQUEST,
    FETCH_FPOSTS_SUCCESS
  } from './postActionTypes';
  
  export const fetchPostsRequest = () => ({
    type: FETCH_POSTS_REQUEST,
  });
  
  export const fetchPostsSuccess = (data) => ({
    type: FETCH_POSTS_SUCCESS,
    payload: {
      posts: data.data.posts,
      currentPage: data.data.pagination.currentPage,
      totalPages: data.data.pagination.totalPages,
      pageSize: data.data.pagination.pageSize,
      totalPosts: data.data.pagination.totalPosts,
    },
    
  });
  
  export const fetchPostsFailure = (error) => ({
    type: FETCH_POSTS_FAILURE,
    payload: error.message,
  });
  
  // Action creator without API call
  export const fetchPosts = (data) => ({
    type: FETCH_POSTS_SUCCESS,
    payload: data,
  });


  export const fetchPostRequest = () => ({
    type: FETCH_POST_REQUEST,
  });
  
  export const fetchPostSuccess = (post) => ({
    type: FETCH_POST_SUCCESS,
    payload: post,
  });
  
  export const fetchPostFailure = (error) => ({
    type: FETCH_POST_FAILURE,
    payload: error,
  });


  export const fetchFPostsRequest = () => ({
    type: FETCH_FPOSTS_REQUEST,
  });
  
  export const fetchFPostsSuccess = (data) => ({
    type: FETCH_FPOSTS_SUCCESS,
    payload: data
    
  });
  
  export const fetchFPostsFailure = (error) => ({
    type: FETCH_FPOSTS_FAILURE,
    payload: error.message,
  });
  