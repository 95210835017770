import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const FooterDiv = styled.div`
    width: 100%;
    text-align: center;
    padding: 30px 0;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(${banner});
    margin-top: 80px;
    color: #fff;

    h4 {
        margin-top: 20px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .icons {
        margin-top: 30px;
        font-size: 20px;
        cursor: pointer;
    }

    .icon {
        margin: 0 13px;
        cursor: pointer;
        padding: 18px 0;
    }

    footer {
        color: #d8d8d8;
        font-size: 12px;
    }

    a {
        text-decoration: none;
        color: inherit; /* Inherit the color from the parent (usually set by the global styles) */
        outline: none; /* Remove the default focus outline (optional, but be mindful of accessibility) */
        background-color: transparent;
    
        &:focus {
          outline: none; /* Remove the default focus outline on focus */
          background-color: transparent; /* Set background color to transparent on focus */
        }
      }

    @media (max-width: 768px) {
        padding: 20px 0; /* Adjust padding for smaller screens */
        margin-top: 40px; /* Reduce margin-top for smaller screens */

        h4 {
            margin-top: 10px;
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 18px; /* Decrease font size for smaller screens */
        }

        .icons {
            margin-top: 20px;
            font-size: 18px; /* Decrease icon size for smaller screens */
        }

        .icon {
            margin: 0 10px; /* Reduce margin for smaller screens */
            padding: 14px 0; /* Adjust padding for smaller screens */
        }

        footer {
            font-size: 10px; /* Decrease font size for smaller screens */
        }
    }

    @media (max-width: 480px) {
        h4 {
            font-size: 16px; /* Further decrease font size for very small screens */
        }

        .icons {
            font-size: 16px; /* Further decrease icon size for very small screens */
        }

        .icon {
            margin: 0 8px; /* Reduce margin even more for very small screens */
            padding: 12px 0; /* Further adjust padding for very small screens */
        }

        footer {
            font-size: 8px; /* Further decrease font size for very small screens */
        }
    }
`;
