import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const BlogsDiv = styled.div`
    height: 100%; /* Changed height to auto to make it responsive */
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url(${banner});
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 10px 0; /* Added padding for spacing */


    .title {
        color: #fff;
        margin-top: 20px; /* Adjusted margin-top for smaller screens */
        font-size: 48px; /* Adjusted font size for better readability */
    }

    @media (max-width: 768px) {

        height: 40vh; 

        .title {
            font-size: 28px; /* Further decrease font size for smaller screens */
        }

    }

    @media (max-width: 480px) {

        height: 40vh; 

        .title {
            font-size: 12px; /* Further decrease font size for smaller screens */
        }

    }
`;

export const BlogCol = styled.div`
    flex-basis: 48%; /* Full width on smaller screens */
    width: 70%;
    align-items: center;
    padding: 20px 2px; /* Adjusted padding for spacing */
    img {
        width: 100%;
    }

    h1 {
        padding-top: 0;
    }

    p {
        padding: 15px 0 25px;
    }

`;

export const Card = styled.div`
width: 50%;
height: 100%;
padding: 20px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 801px) {
    width: 90%;
}

@media (max-width: 480px) {
    width: 90%;
}

`;