import React from "react";
import { ContactDiv, LocationDiv,ContactRow,ContactCol, IconDiv, ContactForm } from "./contact.styled";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import { useSelector } from "react-redux";
import { BiHomeSmile } from "react-icons/bi";
import { FiSmartphone } from "react-icons/fi";
import { FaRegEnvelopeOpen } from "react-icons/fa";
import banner from '../../images/banner.webp';
import CommonHeader from "../../components/subpagediv/commonHeaderDiv.component";
import { useEffect, useState } from "react";
import api from "../../redux/apicall";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';


const Contact = () => {

    const contact = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }
    const [contactData, setContact] = useState(contact);
    const currentUser = useSelector(state => state.user.currentUser); 

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/contact" });
     }, []);

    useEffect(() => {
        if(currentUser && currentUser.email){
            // Use contactData instead of contact
            setContact(prevContact => ({
                ...prevContact,
                name: currentUser.name,
                email: currentUser.email
            }));
        }
    }, [currentUser]);

    const hangleFormSubmit = async () => {
        try {
            // Make a POST request using Axios
            const response = await api.post('/api/contacts', contactData, { withCredentials: true });

            if(response.status === 201) {
                // Handle the response, if needed
                toast.success('Query shared Successfully!');
                const newContact = {
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                }

                setContact(newContact);
            } else {
                toast.info('Error creating contact:');
            }
        } catch (error) {
            // Handle errors
            toast.info('Error creating contact:');
        }
    }

    return(
        <div>
        <CommonHeader imageUrl={banner} title="Contact us for Opportunities" />  
        <LocationDiv>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0407462580797!2d77.56011871433357!3d12.905101519826884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae156273803945%3A0xcba9bffde53ca3bb!2s4%2C%201st%20Cross%20Rd%2C%20Stage%201%20Kumaraswamy%20Layout%2C%20Banashankari%2C%20Bengaluru%2C%20Karnataka%20560078!5e0!3m2!1sen!2sin!4v1650477353776!5m2!1sen!2sin"></iframe>

            </LocationDiv>
            <ContactRow>
                <ContactCol>
                    <IconDiv>
                        <BiHomeSmile className="icon"/>
                        <span>
                            <h5>Kumarswami layout, 1st cross</h5>
                            <p>Bangalore, Karnataka, IN</p>
                        </span>
                    </IconDiv>
                    <IconDiv>
                        <FiSmartphone className="icon"/>
                        <span>
                            <h5>+91 9479460935</h5>
                            <p>Monday to Friday, 9AM to 5PM</p>
                        </span>
                    </IconDiv>
                    <IconDiv>
                        <FaRegEnvelopeOpen className="icon"/>
                        <span>
                            <h5>connect@myappsack.com</h5>
                            <p>Email us your query</p>
                        </span>
                    </IconDiv>

                </ContactCol>
                <ContactCol>
                <ContactForm onSubmit={(e) => {
                            e.preventDefault();  // Prevent the default form submission behavior
                            hangleFormSubmit();
                        }}>
                        <input
                            type="text"
                            name="name"
                            value={contactData.name}
                            onChange={(e) => setContact({ ...contactData, name: e.target.value })}
                            placeholder="Enter your name"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            value={contactData.email}
                            onChange={(e) => setContact({ ...contactData, email: e.target.value })}
                            placeholder="Enter email address"
                            required
                        />
                        <input
                            type="text"
                            name="subject"
                            value={contactData.subject}
                            onChange={(e) => setContact({ ...contactData, subject: e.target.value })}
                            placeholder="Enter your subject"
                            required
                        />
                        <textarea
                            rows="8"
                            name="message"
                            value={contactData.message}
                            onChange={(e) => setContact({ ...contactData, message: e.target.value })}
                            placeholder="Message"
                            required
                        ></textarea>
                        <button type="submit" className="hero-btn red-btn">Send Message</button>
                    </ContactForm>
                </ContactCol>
            </ContactRow>
            <Footer />
        </div>
    )
}

export default Contact;