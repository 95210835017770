import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const HomeBackground = styled.div`
    min-height: 42.25vw; /* 16:9 aspect ratio (9 / 16 * 100) */
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)), url(${banner});
    background-position: center;
    background-size: cover;
    position: relative;


    @media (max-width: 768px) {
        min-height: 48.25vw; /* 16:9 aspect ratio (9 / 16 * 100) */
    }

    @media (max-width: 480px) {
        min-height: 48.25vw; /* 16:9 aspect ratio (9 / 16 * 100) */
    }

    /* Rest of your styles here */
`;

export const SlideDiv = styled.div`
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 40px;

    @media (max-width: 768px) {
        margin-left: 0px;
        margin-right: 0px;
    }

    @media (max-width: 480px) {
        margin-left: 0px;
        margin-right: 0px;
    }
`

