// ReplyItem.js

import styled from 'styled-components';

export const StyledReplyItem = styled.div`
  display: flex;
  align-items: right;
  width: 100%;
  align-items: top;
  border-bottom: 0.8px solid #ccc; /* Bottom border */
  padding: 10px;
`;


export const CircularImage = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ccc;
  margin-top: 5px;
  margin-right: 5px;
`;

export const ReplyContent = styled.div`
  margin-top: 0px;  
  flex: 1;
`;

export const ReplyText = styled.p`
  margin-bottom: 3px;
  flex: 1;
  font-size: 12px;
`;

export const UserName = styled.span`
  font-weight: bold;
  margin-right: 5px;
  flex: 1;
  font-size: 12px;
`;

export const LikeReplyContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LikeIcon = styled.span`
  margin-right: 3px;
  cursor: pointer;
  font-size: 12px;
  color: ${({ liked }) => (liked ? 'red' : 'black')};
`;

export const LikeCount = styled.span`
  font-size: 12px;
  margin-right: 10px;
`;

