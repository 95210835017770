import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  BlogRow, BlogLeftDiv, BlogRightDiv, SpanDiv } from "./blogs.styled";
import { fetchPosts } from "../../redux/post/postActions"; // Adjust the path based on your project structure
import Footer from "../../components/footer/footer.component";
import { Link } from 'react-router-dom';
import Pagination from "../../components/pagination/pagination.component";
import banner from '../../images/banner.webp';
import CommonHeader from "../../components/subpagediv/commonHeaderDiv.component";
import  { fetchPostsAndUpdateStore } from "../../redux/post/postApiCall"
import {formatDate , BASE_URL} from '../../utility'
import ReactGA from 'react-ga4';




import BlogCardRight from "../../components/blogcardright/blogcardright.component";
import { fetchCategoryAndUpdateStore } from "../../redux/category/categoryApiCall";

const Blogs = () =>{

  const dispatch = useDispatch();
  const catDispatcher = useDispatch();
  const postsState = useSelector((state) => state.posts); 
  const categoryState = useSelector((state) => state.categories);
  const { posts, currentPage, totalPages } = postsState;
  const { categories, loading, error } = categoryState;


  const itemsPerPage = 10; // Adjust the number of blog posts per page as needed
  const [currentPosts, setCurrentPosts] = useState([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/blogs" });
 }, []);

  useEffect(() => {
    // Fetch posts when the component mounts
    const fetchData = async () => {
      await fetchCategoryAndUpdateStore(catDispatcher)
    };
    fetchData();
  }, [catDispatcher]);

  useEffect(() => {
    // Fetch posts when the component mounts
    const fetchData = async () => {
      await fetchPostsAndUpdateStore(dispatch, 1, 10);

    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    // Update current posts when posts or currentPage changes
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
   
    if (posts && currentPage !== undefined) {
    setCurrentPosts(posts.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [posts, currentPage, itemsPerPage]);

  const handleCategoryClick = async (categoryId) => {
    await fetchPostsAndUpdateStore(dispatch, 1, 10, categoryId);
  };

  const handlePostCategoryClick = async () => {
    await fetchPostsAndUpdateStore(dispatch, 1, 10);
  };

  const handlePageChange = (pageNumber) => {
    dispatch(fetchPosts(pageNumber));
  };

    return(
        <div>
        <CommonHeader imageUrl={banner} title="Our Futuristic Blog's" />  
        
        <BlogRow>
                    <BlogLeftDiv>
                    <div>
                      
                     {currentPosts.map((blogPost) => (
                        <Link key={blogPost.id} to={`/blogs/${blogPost.slug}/${btoa(blogPost.id)}`}>
                            <BlogCardRight
                              key={blogPost.id}
                              title={blogPost.title}
                              imageUrl={`${BASE_URL}`.concat(blogPost.featuredImage.fileLocation)}
                              publishedDate={formatDate(blogPost.publicationDate)}
                              authorName={blogPost.author.name}
                              description={blogPost.content}
                            />
                        </Link>
                    ))}
                    </div>
                    </BlogLeftDiv>
                    <BlogRightDiv>
                        <h3 onClick={handlePostCategoryClick}>Post Categories</h3>
                        {categories.map((category) => (
                            <SpanDiv onClick={() => handleCategoryClick(category._id)} key={category._id}>
                            <span>{category.name}</span>
                            <span>{category.totalPosts}</span>
                        </SpanDiv>
                        ))}          
                    </BlogRightDiv>
                </BlogRow>
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

            <Footer/>
        </div>
    )
}

export default Blogs;


