import styled from 'styled-components';

export const ProductCardDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 200px; /* Changed height to auto to allow content to determine height */
    align-items: center;
    position: relative;
    margin-bottom: 10px; /* Added margin for spacing between cards */
    
    @media (max-width: 768px) {
        margin-bottom: 5px; /* Further adjust margin for smaller screens */
    }
`;

export const BackgroundImage = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.imageUrl});
    
    @media (max-width: 768px) {
        margin-bottom: 3px; /* Further adjust margin for smaller screens */
    }
`;
