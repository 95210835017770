// store.js
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './user/userReducer';
import categoryReducer from './category/categoryReducer';
import {postsReducer, postReducer, FPostReducer} from './post/postsReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    posts: postsReducer,
    post: postReducer,
    fposts: FPostReducer,
    categories: categoryReducer
  },
  middleware: [thunk],
  devTools: false
});

export default store;
