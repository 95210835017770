import React from "react";
import { ImageAndText, Image, TextContainer, Text} from "./blogsection.styled"

const BlogSection = ({ imageUrl, text }) => (
    <ImageAndText>
      {imageUrl && <Image src={imageUrl} alt="Blog" />}
      {text && (
        <TextContainer>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        </TextContainer>
      )}
    </ImageAndText>
  );
  
  export default BlogSection;