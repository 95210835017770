import React from "react";
import { LeftAlignedContentContainer, Title, AuthorDateContainer, AuthorName, Date, CommentIcon, LikeIcon, Line, LikeCommentContainer, Count, Icon } from "./blogsubheader.styled"

const BlogSubheader = ({ title, authorName, date, likeCount, commentCount }) => (

    <LeftAlignedContentContainer>
      <Title>{title}</Title>
      <AuthorDateContainer>
        <AuthorName>{authorName}</AuthorName>
        <Date>{date}</Date>
      </AuthorDateContainer>
      <Line />
      <LikeCommentContainer>
        <Count>{likeCount === 0 ? "": likeCount}</Count>
        <LikeIcon>&#x2665;</LikeIcon> {/* Heart icon (replace with your desired icon) */}
        <Count>{commentCount === 0 ? "": commentCount}</Count>
        <CommentIcon>&#x1F4AC;</CommentIcon> {/* Comment icon (replace with your desired icon) */}
      </LikeCommentContainer>
      <Line />
    </LeftAlignedContentContainer>
  );
  
  export default BlogSubheader;