// LeftAlignedContent.js
import React from 'react';
import styled from 'styled-components';

export const LeftAlignedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
  width: 100%;
`;

export const Title = styled.h1`
  margin-bottom: 8px;
  font-size: 48px;
`;

export const AuthorDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

export const AuthorName = styled.p`
  color: #666; /* Adjust the color as needed */
  margin-right: 8px;
`;

export const Date = styled.p`
  color: #aaa; /* Adjust the color as needed */
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd; /* Adjust the color as needed */
  margin: 8px 0;
`;

export const LikeCommentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Count = styled.span`
  margin-right: 4px;
  color: #aaa;
`;

export const LikeIcon = styled.span`
  margin-right: 8px;
  font-size: 18px; /* Adjust the font size as needed */
  margin-right: 20px;
  color: #000;
`;

export const CommentIcon = styled.span`
  margin-right: 8px;
  font-size: 18px; /* Adjust the font size as needed */
  margin-right: 20px;

`;



