import styled from 'styled-components';

export const SignInContainer = styled.div`
  width: 100%; /* Adjusted width to make it responsive */
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  padding: 20px; /* Adjusted padding for spacing */
  border-radius: 5px;
  border: 1px solid #f44336;

  h1 {
    text-align: left;
    font-size: 16px; /* Adjusted font size for better readability */
    color: #333; /* Changed text color for better visibility */
  }

  h6 {
    text-align: left;
    font-size: 12px; /* Adjusted font size for better readability */
    color: #949494;
  }
`;

export const SignInTitle = styled.h2`
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const SignInForm = styled.form`
  width: 100%; /* Adjusted width to make it responsive */
  display: flex;
  align-items: left;
  flex-direction: column;

  input {
    margin: 10px 0;
    padding: 10px; /* Adjusted padding for better input size */
    height: 40px;
    border-radius: 5px;
    border: 1px solid #f44336;

    &:focus {
      outline: none !important;
      border-color: #f44336;
      box-shadow: 0 0 10px #f44336;
    }
  }
`;

export const SignInButton = styled.button`
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #f44336;
  background: #f44336;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid #f44336;
    background: #c74336;
    transition: 0.7s;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;