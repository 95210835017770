import { fetchCaregoryFailure, fetchCaregoryRequest, fetchCaregorySuccess } from "./categoryActions";
import api from "../apicall";
import { toast } from "react-toastify";

export const fetchCategories = async () => {
  try {
    const response = await api.get(`/api/categories`);
    const data = response.data;
      return {
        status: response.status,
        isSuccessfull: true,
        isError: false,
        data,
      };
  } catch (error) {
      

    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};


// Function to dispatch actions based on API response
export const fetchCategoryAndUpdateStore = async (dispatch) => {

  dispatch(fetchCaregoryRequest());
  try {
    const response = await fetchCategories();
    if (response.isSuccessfull) {
      dispatch(fetchCaregorySuccess(response.data));
    } else {
      dispatch(fetchCaregoryFailure(response.data));
    }
  } catch (error) {
    dispatch(fetchCaregoryFailure(error));
  }

};


