import React from "react";
import Header from "../../components/header/header.component"
import { HomeBackground, HomeContainer, SlideDiv } from "./home.styled";
import SubHeader from "../../components/home/sub-header/sub-header.component";
import Footer from "../../components/footer/footer.component"
import { ImageTextComponent } from "../../components/image-text/image-text.component";
import out_mission from "../../images/our-mission.webp"
import our_vision from "../../images/our-vision.webp"

import {MultipleRows} from "../../components/multiplerowsslider/multipleslider.component";



const Home = () =>{

  

    const ourMissionText = <div>
    <p>At MyAppsack, we are driven by a mission to empower businesses and individuals through the transformative power of mobile technology. We believe that apps and games have the potential to revolutionize industries, enhance communication, and provide endless entertainment. Our mission is to harness this potential and create exceptional mobile experiences that leave a lasting impact.</p>
    <br />
    <p>Our mission extends beyond mere app development. We strive to create apps and games that not only entertain and engage but also enrich lives and drive positive change. We are committed to using our platform to support social and environmental causes, promoting diversity and inclusion, and fostering a more connected and equitable world.</p>
    <br />
    <p>We are committed to measuring our impact and making a positive difference in the world. We track our progress towards our mission through key metrics such as user engagement, social impact, and environmental sustainability. We believe that accountability is essential for ensuring that our efforts are aligned with our mission and values.</p>
  </div>

const VisionStatements = 
      <div>
        <p>
          We envision a world where mobile technology is a seamless and empowering
          force in people's lives. Our apps and games will be ubiquitous,
          seamlessly integrated into daily routines, and providing transformative
          experiences that enrich, educate, and entertain.
        </p>
        <br />
  
        <p>
          We envision a future where our games are not just a source of
          entertainment but also a catalyst for creativity, social connection, and
          personal growth. Our immersive worlds will provide endless
          possibilities for exploration, collaboration, and self-expression.
        </p>
        <br />
  
        <p>
          We envision a world where our apps are indispensable tools for
          businesses of all sizes, streamlining operations, enhancing
          productivity, and fostering innovation. Our technology will empower
          businesses to reach new heights, connect with customers on a deeper
          level, and make a positive impact on the world.
        </p>
        <br />
  
       
        <p>
          We envision a world where MyAppsack is a global force in the app
          development industry, recognized for our exceptional products and
          unwavering commitment to quality. Our apps and games will transcend
          borders, cultures, and languages, connecting people worldwide through
          shared experiences.
        </p>
      </div>
   
    return(
        <div>
            <HomeBackground>
            <Header/>
            <SubHeader/> 
            </HomeBackground>
            <SlideDiv>
              <MultipleRows/>
            </SlideDiv>
            <ImageTextComponent
                imageSrc={out_mission}
                text={ourMissionText}
                reverse={false} 
            />
            <ImageTextComponent
                imageSrc={our_vision}
                text={VisionStatements}
                reverse={true} 
            />
            
            <Footer/>
        </div>
    );
}

export default Home;