import React from 'react';
import { PaginationContainer, PaginationButton } from './pagination.styled';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <PaginationContainer>
      <PaginationButton disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
        Prev
      </PaginationButton>
      {pageNumbers.map((number) => (
        <PaginationButton
          key={number}
          onClick={() => onPageChange(number)}
          className={currentPage === number ? 'active' : ''}
        >
          {number}
        </PaginationButton>
      ))}
      <PaginationButton disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)}>
        Next
      </PaginationButton>
    </PaginationContainer>
  );
};

export default Pagination;