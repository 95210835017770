import React, { useEffect, useState , useReducer} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card } from './blogdetail.styled';
import Footer from '../../components/footer/footer.component';
import BlogSection from '../../components/blogsection/blogsection.component';
import BlogSubheader from '../../components/blogsubheader/blogsubheader.component';
import mas_logo_black from '../../images/mas_logo_black.webp';
import Header from '../../components/header/header.component';
import { formatDate, BASE_URL } from '../../utility';
import { fetchPostAndUpdateStore } from '../../redux/post/postApiCall';
import { fetchPostSuccess } from '../../redux/post/postActions';
import BlogCommentAndLike from '../../components/blogcomentandlike/blogcommentandlike.component';
import LoadingPage from '../../components/loading';
import NotFoundPage from '../../components/notfound';
import { postReducer } from '../../redux/post/postsReducer';
import ReactGA from 'react-ga4';


const BlogDetail = () => {
  const initialStatePost = {
    post: null,
    loading: false,
    error: null,
  };  
  const [state, dispatchPost] = useReducer(postReducer, initialStatePost);
  const { post, loading, error } = state;
  const postsState = useSelector((state) => state.posts);
  const { posts } = postsState;
  const { id } = useParams();
  const decodedId = atob(id);


 


  useEffect(() => {
    const fetchData = async () => {
      const foundPost = posts.find((post) => post.id === decodedId);
  
      if (foundPost) {
        dispatchPost(fetchPostSuccess(foundPost));
      } else {
        await fetchPostAndUpdateStore(dispatchPost, decodedId);
      }
    };
  
    fetchData();
  }, [dispatchPost]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/blog/}"+decodedId });
 }, []);



  if (loading) {
    return LoadingPage;
  }
  else if (error) {
    return NotFoundPage;
  }
  else  if(!post){
    return NotFoundPage;
  };


  return (
    <div>
      <Header blackLogo={mas_logo_black} navLiTextColor="#000000" />
      <Card>
        <BlogSubheader
          title={post.title}
          authorName={post.author.name}
          date={formatDate(post.publicationDate)}
          likeCount={post.likesCount}
          commentCount={post.comments.length}
        />

        <BlogSection
          imageUrl={`${BASE_URL}`.concat(post.featuredImage.fileLocation)}
          text={post.content}
        />
        <BlogCommentAndLike post={post}
        dispatch={dispatchPost} />
      </Card>
      <Footer />
    </div>
  );
};

export default BlogDetail;
