import React from "react";
import { BlogCardContainer ,BlogImage, BlogCardContent, BlogTitle, BlogMeta, AuthorName, BlogDescription,
     StyledComponent, LikeContainer, LikeIcon, CommentContainer, CommentIcon} from "./homeblog.styled";

const HomeBlogCard = ({ title, imageUrl, publishedDate, authorName, description, totalLikes, totalComments }) => {
    const createMarkup = () => ({ __html: description });
  
    return (
      <BlogCardContainer>
        <BlogImage src={imageUrl} alt="Blog Image" />
        <BlogCardContent>
          <BlogTitle>{title}</BlogTitle>
          <BlogMeta>
            <div>Published on {publishedDate}</div>
          </BlogMeta>
          <AuthorName>By {authorName}</AuthorName>
          <BlogDescription dangerouslySetInnerHTML={createMarkup()} />
          <StyledComponent>
            <LikeContainer>
                <LikeIcon >
                &#x2665;
                </LikeIcon>
                <span>{totalLikes}</span>
            </LikeContainer>
            <CommentContainer>
                <CommentIcon>&#x1F4AC;</CommentIcon>
                <span>{totalComments}</span>
            </CommentContainer>
        </StyledComponent>
        </BlogCardContent>
      </BlogCardContainer>
    );
  };
  
  export default HomeBlogCard;
  