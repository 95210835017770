import React from 'react';
import styled from 'styled-components';

export const ImageAndText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 40px;
  flex-direction: column;
  margin-top: 20px;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const TextContainer = styled.div`
  max-width: 100%;
  padding: 20px;
  border-radius: 8px;
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 1.6;
  color: #3A3B3C;
  text-align: left; /* Add this line to align text to the left */
`;
