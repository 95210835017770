//apiCall.js
import api from '../apicall';
import { setCurrentUser , clearCurrentUser} from './userActions';
import { toast } from 'react-toastify';



export const fetchCurrentUser = () => {
  return async (dispatch) => {
    try {
      // Make the API request to get the current user information using Axios
      const response = await api.get('/api/currentUser', {
        withCredentials: true,
      });
      const data = response.data;
      // If the request is successful, update the Redux store with the user information
      dispatch(setCurrentUser(data.user));


    } catch (error) {
      dispatch(clearCurrentUser());


      // Log the specific error response if available
      if (error.response) {
      }

      // Log the request config

      // Handle errors as needed
    }
  };
};


export const signUpUser = async (userData) => {
  try {
    const response = await api.post('/api/signup', userData, { withCredentials: true });

    if (response.status === 201) {

      return {
        status: response.status,
        isSuccessfull: true,
        isError: false,
        data: response.data.user,
      };
    }
  } catch (error) {
    // Handle errors, e.g., show an error message to the user
    toast.info(error.response.data.error);

    // Return an error object
    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};

export const signInUser = async (userData) => {
  try {
    const response = await api.post('/api/signin', userData, { withCredentials: true });

    if (response.status === 200) {
      return {
        status: response.status,
        isSuccessfull: true,
        isError: false,
        data: response.data.user,
      };
    }
  } catch (error) {
    // Handle errors, e.g., show an error message to the user
    toast.info(error.response.data.error);

    // Return an error object
    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};

export const signOutUser = async () => {
  try {
    const response = await api.get('/api/logout', { withCredentials: true });
    if (response.status === 200) {
      return {
        status: response.status,
        isSuccessfull: true,
        isError: false,
        data: response.data,
      };
    }
    
  } catch (error) {
    // Handle errors, e.g., show an error message to the user
    toast.info(error.response.data.error);

    // Return an error object
    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};