import React, { useEffect } from "react";
import { AboutDiv, AboutRow, AboutCol } from './about.styled';
import Header from "../../components/header/header.component";
import about_image from '../../images/about_image2.webp';
import Footer from "../../components/footer/footer.component"
import CommonHeader from "../../components/subpagediv/commonHeaderDiv.component";
import banner from '../../images/banner.webp';
import ReactGA from 'react-ga4';



const About = () =>{

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/about" });
     }, []);

    return (
        <div>
         <CommonHeader imageUrl={banner} title="About Us" />   
        <AboutRow className="row">
        <AboutCol className="col">
            <h1>We are the digital product company</h1>
            <p>As a product-based app and game development company,
                 MyAppsack is dedicated to crafting exceptional 
                 mobile experiences that captivate users and empower businesses.
                  We are passionate about infusing technology with creativity,
                   transforming ideas into captivating realities that resonate
                    with audiences worldwide.</p>

            <p>
            Our team of skilled developers and designers is committed to excellence,
             meticulously crafting each app and game with the utmost attention to detail.
              We believe in pushing the boundaries of innovation, exploring cutting-edge 
              technologies to deliver experiences that are both immersive and engaging.
            </p>
            <p>
            At MyAppsack, we understand the power of mobile applications to transform industries,
             enhance communication, and provide endless entertainment.
              We are driven by the desire to create not just apps and games,
               but rather experiences that leave a lasting impact on users' lives.
            </p>
            <p>
            With a steadfast commitment to quality, innovation, and customer satisfaction,
             MyAppsack is poised to become a global leader in the app and game development
              landscape. We invite you to embark on this journey with us, as we transform 
              ideas into captivating mobile experiences that leave an indelible mark on the world.  
            </p>
        </AboutCol>
        <AboutCol className="col">
            <img src={about_image} />        
        </AboutCol>
    </AboutRow>
    <Footer/>
    </div>
    )
}

export default About;