import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const ProductDiv = styled.div`
    height: 50vh; /* Changed height to auto to make it responsive */
    width: 100%;
    align-items: center;
    background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${banner});
    background-position: center;
    background-size: cover;
    position: relative;
    text-align: center; /* Centered text */
    padding: 20px 0;
    
    .title {
        color: #fff;
        margin-top: 20px; /* Adjusted margin-top for smaller screens */
        font-size: 48px; /* Adjusted font size for better readability */
    }

    @media (max-width: 768px) {

        height: 40vh; 

        .title {
            font-size: 28px; /* Further decrease font size for smaller screens */
        }

    }

    @media (max-width: 480px) {

        height: 40vh; 

        .title {
            font-size: 12px; /* Further decrease font size for smaller screens */
        }

    }
`;

export const ProductsContainer = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid columns */
    gap: 20px; /* Adjusted gap for spacing */
    justify-content: center;
    padding-top: 20px; /* Adjusted padding for spacing */
    margin-left: 5%;
    margin-right: 5%;

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Further adjust columns for smaller screens */
    }
`;
