import React, { useState , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { replyLikeApi } from '../../redux/post/comment/reply/replyApiCall';
import { StyledReplyItem, CircularImage, ReplyContent, ReplyText, UserName, LikeIcon, LikeCount, LikeReplyContainer } from "./replytext.styled"

const ReplyItem = ({ reply}) => {
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [myState, setMyState] = useState(reply);
    const currentUser = useSelector(state => state.user.currentUser); 


    useEffect(() => {
      if(currentUser){
        const isCurrentUserLiked = reply.likes.includes(currentUser.userId);
        if (isCurrentUserLiked) {
          setLiked(true)
        } else {
          setLiked(false)
        }
      }
      setLikeCount(reply.likes.length)
      setMyState(reply);
    }, [myState, reply]);
  
    const handleLikeClick = async () => {
      if(currentUser){
        const response = await replyLikeApi(reply.id);
        if(response.status === 200){
          const isCurrentUserLiked = response.data.likes.includes(currentUser.userId);
          if (isCurrentUserLiked) {
            setLiked(true)
            setLikeCount(likeCount + 1)
          } else {
            setLiked(false)
            setLikeCount(likeCount - 1)
        }
        }
      }
      };

  
    return (
      <StyledReplyItem>
        <CircularImage />
        <ReplyContent>
        
        <UserName>{myState.replyer.name}</UserName>

          <ReplyText>{myState.text}</ReplyText>
          
        </ReplyContent>
        <LikeReplyContainer>
          <LikeIcon liked={liked} onClick={handleLikeClick}>
            &#x2665;
          </LikeIcon>
          <LikeCount>{likeCount}</LikeCount>
        </LikeReplyContainer>
      </StyledReplyItem>
    );
  };
  
  export default ReplyItem;
  