import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const AboutDiv = styled.div`
    height: 50vh; /* Changed height to auto to make it responsive */
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${banner});
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 20px 0; /* Added padding for spacing */

    .title {
        color: #fff;
        margin-top: 20px; /* Adjusted margin-top for smaller screens */
        font-size: 48px; /* Adjusted font size for better readability */
    }
    
    @media (max-width: 768px) {
        height: 40vh;

        .title {
            color: #fff;
            font-size: 34px; /* Adjusted font size for better readability */
        }
    }

    @media (max-width: 480px) {
        height: 30vh;

        .title {
            color: #fff;
            font-size: 24px; /* Adjusted font size for better readability */
        }


    }
`;

export const AboutRow = styled.div`
    width: 80%; /* Adjusted width to make it responsive */
    margin: auto;
    display: flex;
    flex-direction: column; /* Changed flex direction to column for mobile screens */
    margin-top: 20px; /* Adjusted margin-top for smaller screens */
    justify-content: space-between;
    padding-top: 30px; /* Adjusted padding-top for spacing */
    padding-bottom: 20px; /* Adjusted padding-bottom for spacing */
    padding-left: 10px; /* Adjusted padding-left for spacing */
    padding-right: 10px; /* Adjusted padding-right for spacing */

    @media (min-width: 768px) {
        flex-direction: row; /* Switch back to row layout for screens wider than 768px */
        flex-wrap: wrap; /* Allow flex items to wrap to the next row on smaller screens */
    }
`;

export const AboutCol = styled.div`
    flex-basis: 48%; /* Full width on smaller screens */
    padding: 20px 2px; /* Adjusted padding for spacing */
    img {
        width: 100%;
    }

    h1 {
        padding-top: 0;
    }

    p {
        padding: 15px 0 25px;
    }

`;

