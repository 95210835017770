import styled from "styled-components";

export const ArrowIcon = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-80%);
    font-size: 24px;
    cursor: pointer;
    color: white;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
`;



export const StyledSliderContainer = styled.div`
    width: 100%;
    display: flex;
    padding: '20px 100px 20px 100px'
    flex-direction: row;
`;

// Styled component for each slide
export const StyledSlide = styled.div` 
    padding: '20px 100px 20px 100px'
    .link-without-underline {
        text-decoration: none;
    }
`;