import React from "react";
import { FooterDiv } from "./footer.styled";
import {FaFacebook}  from "react-icons/fa";
import {RiInstagramFill} from "react-icons/ri"
import {AiFillTwitterCircle} from "react-icons/ai"

const Footer = () =>{
    return (
        <FooterDiv>
            <h4>About Us</h4>
            As a product-based app and game development company,
            <br />
                 MyAppsack is dedicated to crafting exceptional 
                 mobile experiences that 
                 <br/>captivate users and empower businesses.
                  We are passionate about infusing technology with creativity,
                  <br/>
                   transforming ideas into captivating realities that resonate
                    with audiences worldwide.
            <div class="icons">
                <a href="https://www.facebook.com/myappsack5/"><FaFacebook className = "icon"/></a>
                <a href="https://www.instagram.com/myappsack/"><RiInstagramFill className = "icon"/></a>
                <a href="https://twitter.com/myappsack"><AiFillTwitterCircle className = "icon"/></a>
            </div>
            <footer>&copy; Copyright 2024 myappsack</footer>
        </FooterDiv>
    )
}

export default Footer;