// CommonHeaderStyles.js
import styled from 'styled-components';

export const CommonHeaderDiv = styled.div`
  height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)), url(${props => props.imageUrl});
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 20px 0;

  .title {
    color: #fff;
    margin-top: 20px;
    font-size: 48px;
  }

  @media (max-width: 768px) {
    height: 40vh;

    .title {
      color: #fff;
      font-size: 34px;
    }
  }

  @media (max-width: 480px) {
    height: 30vh;

    .title {
      color: #fff;
      font-size: 24px;
    }
  }
`;
