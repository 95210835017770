import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reportThis } from '../../redux/post/reportApiCall';
import {
  StyledComponent,
  LikeContainer,
  LikeIcon,
  CommentContainer,
  ThreeDots,
  Popup,
  ReportText,
  CommentIcon,
  StyledWrapper,
} from './blogcommentandlike.styled';
import CommentComponent from '../commentcomponent/comment.comonent';
import { postLikeApi } from '../../redux/post/postApiCall';
import { fetchPostSuccess } from '../../redux/post/postActions';

const BlogCommentAndLike = ({ post, dispatch }) => {
  const [myState, setMyState] = useState(post);
  const currentUser = useSelector((state) => state.user.currentUser);
  const popupRef = useRef();
  const [liked, setLiked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });

  useEffect(() => {
    if (currentUser) {
      const isCurrentUserLiked = post.likes.includes(currentUser.userId);
      if (isCurrentUserLiked) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    }
    setMyState(post);
  }, [post, currentUser]);

  const handleLikeClick = async () => {
    try {
      if (post) {
        const response = await postLikeApi(post.id);
        if (response.status === 201) {
          dispatch(fetchPostSuccess(response.data));
          setLiked(true); // Provide visual feedback, for example, change the color of the like button
        } else if (response.status ===401){
          toast.info('Can be done after login');
        }
      }
    } catch (error) {
      // Handle error if the API call fails
      toast.info('Error liking post');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && showPopup) {
        setShowPopup(false);
      }
    };
  
    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    
  
    document.addEventListener('click', handleDocumentClick);
  
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [popupRef, showPopup]);

  const handleCommentClick = () => {
    setShowComment(!showComment); // Provide visual feedback, for example, change the style of the comment section
  };

  const handleReportClick = async  () => {
    if(currentUser){
        await reportThis(currentUser.userId, post.id);
    }else{
      toast.infoF("Signup to report")
    }
  };

  const handleThreeDotsClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    setPopupPosition({ top: rect.bottom, right: window.innerWidth - rect.right });
    setShowPopup(!showPopup);
  };

  return (
    <StyledWrapper>
      <StyledComponent>
        <LikeContainer>
          <LikeIcon liked={liked} onClick={handleLikeClick}>
            &#x2665;
          </LikeIcon>
          <span>{myState.likesCount}</span>
        </LikeContainer>
        <CommentContainer onClick={handleCommentClick}>
          <CommentIcon>&#x1F4AC;</CommentIcon>
          <span>{myState.comments.length}</span>
        </CommentContainer>
        <Popup ref={popupRef} show={showPopup} top={`${popupPosition.top}px`} right={`${popupPosition.right}px`}>
          <ReportText onClick={handleReportClick}>Report</ReportText>
        </Popup>
        <ThreeDots onClick={handleThreeDotsClick}>&#x22EE;</ThreeDots>
      </StyledComponent>
      <CommentComponent isVisible={showComment} post={post} dispatch={dispatch} />
    </StyledWrapper>
  );
};

export default BlogCommentAndLike;
