import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

import { ArrowIcon, StyledSliderContainer, StyledSlide } from "./customArrow.styled";
import  HomeBlogCard from "../homeblog/homeblog.component";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { fetchFeaturedPostsAndUpdateStore } from "../../redux/post/postApiCall";
import LoadingPage from "../loading"
import NotFoundPage from "../notfound"
import { formatDate , BASE_URL} from "../../utility";
import { toast } from "react-toastify";





export const  MultipleRows  = () => {

    const dispatch = useDispatch();
    const postsState = useSelector((state) => state.fposts); // Adjust the state slice based on your Redux setup
    const { posts, loading, error } = postsState;
    

    useEffect( async () => {
      const fetchData = async () => {
        try {
          // Start fetching, set loading to true
          await fetchFeaturedPostsAndUpdateStore(dispatch);

  
          // Fetch successful, loading will be handled in the action
        } catch (error) {
          // Handle errors, loading will be handled in the action
          toast.info('Error fetching featured posts:');
        }
      };
  
  
      await fetchData();
    }, [dispatch]);


      
      const LeftArrow = ({ onClick }) => (
        <ArrowIcon style={{ left: 0 }} onClick={onClick}>
          <FaArrowLeft color="black"/>
        </ArrowIcon>
      );
      
       const RightArrow = ({ onClick }) => (
        <ArrowIcon style={{ right: 0 }} onClick={onClick}>
          <FaArrowRight color="black"/>
        </ArrowIcon>
      );
    

      const settings = {
        className: "center",
        centerMode: false, // Changed to false to have multiple columns
        infinite: true,
        slidesToShow: 1, // Adjusted to show 3 slides in a row
        speed: 500,
        rows: 2,
        slidesPerRow: 3, // Adjusted to show 3 slides in a row
        nextArrow: <RightArrow />, // Adjust
        prevArrow: <LeftArrow />,
        responsive: [
            {
              breakpoint: 780,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 6,
                slidesPerRow: 1, 
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                rows: 6,
                slidesPerRow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

      if(loading){
        return LoadingPage()
      }else if(error){
        return NotFoundPage()
        }

      return (
        <div >
         <Slider {...settings}>
          {posts.map((data) => (
            
            <div key={data.id} style={{ margin: '0 40px 0 40px' }}>
              <Link key={data.id} style={{ textDecoration: 'none' }} to={`/blogs/${data.slug}/${btoa(data.id)}`}>
                <HomeBlogCard
                key={data.id}
                title={data.title}
                imageUrl={`${BASE_URL}`.concat(data.featuredImage.fileLocation)}
                publishedDate={formatDate(data.publicationDate)}
                authorName={data.author.name}
                description={data.content}
                totalLikes={data.likesCount}
                totalComments={ data.comments? data.comments.length : 0}
                />
                 </Link>
            </div>
            ))}
          </Slider>
        </div>
      );
    }


  