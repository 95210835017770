import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { signOutUser, fetchCurrentUser } from '../../redux/user/userApiCall';
import logo from '../../images/mas_logo_white.webp';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiFillCloseSquare  } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";



import { Link } from 'react-router-dom';
import {
  Nav,
  NavLink,
  NavLi,
  HamburgerIcon,
  CloseIcon,
  MobileMenu,
} from "./header.styled";



const Header = ({ navLiTextColor, blackLogo }) => {

    const currentUser = useSelector(state => state.user.currentUser); // Assuming the slice containing user information is named 'user'

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleSignOut = async () => {
      try {
        // Dispatch action to sign out the user
        const response = await signOutUser();
    
        if (response.isSuccessfull) {
          // Successful sign-out, you can perform additional actions if needed
          await dispatch(fetchCurrentUser());
          navigate('/');
        } else {
          // Handle error, show an error message, etc.
          toast.info('Error during signout:');
        }
      } catch (error) {
        // Handle unexpected errors
        toast.info('Unexpected error during signout:');
      }
    };
    
  


  return (
    <Nav className="header">
      <row>
      <Link to="/">
            {blackLogo ? (
              <img src={blackLogo} alt={blackLogo} />
            ) : (
              <img src={logo} alt={logo} />
            )}
          </Link>
      </row>
      <NavLink>
      <ul>
          <NavLi to="/blogs" textColor={navLiTextColor}>BLOGS</NavLi>
          <NavLi to="/about" textColor={navLiTextColor}>ABOUT</NavLi>
          <NavLi to="/contact" textColor={navLiTextColor}>CONTACT</NavLi>
          {currentUser ? (
            <NavLi to="#" onClick={handleSignOut} textColor={navLiTextColor}>SIGN OUT</NavLi>
          ) : (
            <NavLi to="/signin" textColor={navLiTextColor}>SIGN IN</NavLi>
          )}
        </ul>
       </NavLink>
       <HamburgerIcon>
      <GiHamburgerMenu size={32} color={navLiTextColor ? "black" : "white"}  onClick={toggleMobileMenu} style={{ display: isMobileMenuOpen ? 'none' : 'block' }}/>
      </HamburgerIcon>
      <CloseIcon>
      <AiFillCloseSquare size={32} color={navLiTextColor ? "black" : "white"} onClick={toggleMobileMenu} style={{ display: isMobileMenuOpen ? 'block' : 'none' }}/>  
      <MobileMenu onClick={toggleMobileMenu} style={{ display: isMobileMenuOpen ? 'block' : 'none' }} >
        <ul>
          <li>
          <NavLi to="/blogs" textColor={navLiTextColor}>BLOGS</NavLi>
          </li>
          <li>
          <NavLi to="/about" textColor={navLiTextColor}>ABOUT</NavLi>
          </li>
          <li>
          <NavLi to="/contact" textColor={navLiTextColor}>CONTACT</NavLi>
          </li>
          <li>
          {currentUser ? (
            <NavLi to="#" onClick={handleSignOut} textColor={navLiTextColor}>SIGN OUT</NavLi>
          ) : (
            <NavLi to="/signin" textColor={navLiTextColor}>SIGN IN</NavLi>
          )}
          </li>
        </ul>
      </MobileMenu>
      </CloseIcon>
    </Nav>
  );
};

export default Header;
