import api from "../apicall";
import { toast } from "react-toastify";

export const reportThis = async (reporterUserId, reportedItemId) => {
  try {
    const response = await api.post(`/api/report`, {
            reporterUserId,
            reportedItemId
    }, { withCredentials: true });
    if(response.statusCode === 201){
        toast.success("Reported successfully");
    }else if(response.statusCode === 400){
        toast.info("Already reported")
    }
  } catch (error) {

    if(error.response.statusCode === 400){
        toast.info("Already reported")
    }else{
    toast.info("Already reported")
    }
  }
};