import React, { useEffect } from 'react';
import { SignInUpDiv, SignInAndSignUpContainer } from './sign-in-up.styled';
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import SignIn from '../../components/signin/sign-in.component';
import SignUp from '../../components/signup/sign-up.component';
import banner from '../../images/banner.webp';
import CommonHeader from "../../components/subpagediv/commonHeaderDiv.component";
import ReactGA from 'react-ga4';


const SignInUp = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/signin" });
     }, []);
     
    return(
        <div>
        <CommonHeader imageUrl={banner} title="Sign up & join our family" />  
        <SignInAndSignUpContainer>
                <SignIn/>
                <SignUp/>
            </SignInAndSignUpContainer>
            <Footer/>
        </div>
    );
}

export default SignInUp;