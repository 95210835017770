import React from 'react';
import styled from 'styled-components';

export const BlogCardContainer = styled.div`
  max-width: 80%;
  margin: 10px auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%; /* Adjusted to allow the image to take full height in column layout */
  }
`;

export const BlogImage = styled.img`
  width: width: 100%;
  max-height: 100%; /* Adjusted to allow the image to adjust its height automatically */
  object-fit: cover; /* Keep the image aspect ratio and cover the container */

  @media (max-width: 600px) {
    width: 100%; /* Adjusted to allow the image to take full height in column layout */
  }
`;

export const BlogCardContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const BlogTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

export const BlogMeta = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: #777;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AuthorName = styled.div`
  margin-top: 2px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #555;
`;

export const BlogDescription = styled.div`
  margin-top: 15px
  font-size: 16px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;

    @media (max-width: 600px) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      max-height: 4em  
    }
`;


export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LikeIcon = styled.span`
  margin-right: 5px;
  cursor: pointer;
  color: red;
`;

export const CommentIcon = styled.span`
  margin-right: 5px;
  cursor: pointer;
  color: black; /* Set the color for the comment icon */
`;

export const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;  
  margin-left: 20px;
  cursor: pointer;
`;

export const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding: 0px 0px 0px 0px;
  height: 25px;
`;
