import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const ContactDiv = styled.div`
        height: 50vh; /* Changed height to auto to make it responsive */
        width: 100%;
        background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${banner});
        background-position: center;
        background-size: cover;
        text-align: center;
        padding: 20px 0; /* Added padding for spacing */

        .title {
            color: #fff;
            margin-top: 40px; /* Adjusted margin-top for smaller screens */
            font-size: 30px; /* Adjusted font size for better readability */
        }

        @media (max-width: 768px) {

            height: 40vh; 

            .title {
                font-size: 28px; /* Further decrease font size for smaller screens */
            }

        }

        @media (max-width: 480px) {

            height: 35vh; 

            .title {
                font-size: 12px; /* Further decrease font size for smaller screens */
            }

        }
`;

export const LocationDiv = styled.div`
    width: 80%; /* Adjusted width to make it responsive */
    margin: auto;
    padding: 80px 0; /* Adjusted padding for spacing */

    iframe {
        width: 100%;
        height: 250px;
        border: 0;
    }
`;

export const ContactRow = styled.div`
    width: 80%; /* Adjusted width to make it responsive */
    margin: auto;
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to the next row on smaller screens */
`;

export const ContactCol = styled.div`
    flex-basis: 100%; /* Full width on smaller screens */
    margin-bottom: 20px; /* Adjusted margin for spacing */

    @media (min-width: 768px) {
        flex-basis: 48%; /* Restore original width on larger screens */
    }
`;

export const IconDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Adjusted margin for spacing */

    .icon {
        font-size: 24px; /* Adjusted font size for better readability */
        color: #f44336;
        margin: 10px;
        margin-right: 20px; /* Adjusted margin for spacing */
    }

    p {
        padding: 0;
    }

    h5 {
        font-size: 18px; /* Adjusted font size for better readability */
        margin-bottom: 5px;
        color: #555;
        font-weight: 400;
    }
`;

export const ContactForm = styled.form`
    input, textarea {
        width: 100%;
        padding: 15px;
        margin-bottom: 17px;
        outline: none;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    .hero-btn {
        display: inline-block;
        text-decoration: none;
        padding: 12px 34px;
        font-size: 14px; /* Adjusted font size for better readability */
        position: relative;
        cursor: pointer;
        border: 1px solid #f44336;
        background: transparent;
        color: #f44336;

        &:hover {
            border: 1px solid #f44336;
            background: #f44336;
            transition: 0.7s;
            color: #fff;
        }
    }
`;
