import styled from 'styled-components';
import banner from '../../images/banner.webp';

export const BlogsDiv = styled.div`
    height: 50vh; /* Changed height to auto to make it responsive */
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${banner});
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 20px 0; /* Added padding for spacing */


    .title {
        color: #fff;
        margin-top: 20px; /* Adjusted margin-top for smaller screens */
        font-size: 48px; /* Adjusted font size for better readability */
    }

    @media (max-width: 768px) {

        height: 40vh; 

        .title {
            font-size: 28px; /* Further decrease font size for smaller screens */
        }

    }

    @media (max-width: 480px) {

        height: 40vh; 

        .title {
            font-size: 12px; /* Further decrease font size for smaller screens */
        }

    }
`;

export const BlogRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; /* Keep row layout by default */
    width: 80%; /* Adjusted width to make it responsive */
    margin: auto;
    justify-content: space-around;
    margin-top: 20px; /* Adjusted margin-top for smaller screens */
    padding: 20px 0; /* Adjusted padding for spacing */

    @media (max-width: 768px) {
        flex-direction: column; /* Change to column layout for screens up to 768px */
    }
`;

export const BlogLeftDiv = styled.div`
    flex-basis: 70%; /* Adjusted flex-basis for larger screens */

    a {
        text-decoration: none; /* Remove underline */
        color: inherit; /* Use the default text color */
    
        &:hover {
          text-decoration: none; /* Remove underline on hover */
        }
      }
`;

export const BlogRightDiv = styled.div`
    flex-basis: 25%; /* Adjusted flex-basis for larger screens */

    h3 {
        background: #f44336;
        color: #fff;
        padding: 7px 7px;
        font-size: 16px;
        margin-bottom: 20px;
        text-align: center;
    }
`;



export const SpanDiv = styled.div`
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to the next row on smaller screens */
    align-items: center;
    justify-content: space-between;
    color: #555;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px; /* Adjusted font size for better readability */

    @media (max-width: 480px) {
        font-size: 12px; /* Further decrease font size for smaller screens */
    }
`;


