import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { StyledContainer, CircularImage, CylindricalInput, Button, CommentList, StyledWrapper, Popup, SignUpText } from './comment.styled';
import CommentItemComponent from '../commentitem/commentitem.component';
import { useNavigate } from 'react-router-dom';
import { newCommentApi } from '../../redux/post/comment/commentApiCall';
import { fetchPostSuccess } from '../../redux/post/postActions';
import { toast } from 'react-toastify';




const CommentComponent = ({ isVisible, post, dispatch }) => {


    const currentUser = useSelector(state => state.user.currentUser); 

    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [myState, setMyState] = useState(post);
    const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef();

   

    const navigate = useNavigate();
    const handleAddComment = async (event) => {
      if(currentUser) {
      if (comment.trim() !== '') {
        
        try {
        const response = await newCommentApi({
          commenter: currentUser.id,
          text: comment
        }, post.id);
        if (response.status === 201){
            // Dispatch fetchPostSuccess action to update post data
            dispatch(fetchPostSuccess(response.data));
            setComment('');
        }else{
          
        }
        } catch (error) {
          // Handle error if the API call fails
          toast.info('Error adding comment:');
        }

      }
    }else{
      const rect = event.target.getBoundingClientRect();
      setPopupPosition({ top: rect.bottom, right: window.innerWidth - rect.right });
      setShowPopup(!showPopup);
    }
    };

    const handleSignUpClick = () => {
      navigate('/signin');
    };

    

    useEffect(() => {
      if (post) {
        setMyState(post);
    
        if (post && post.comments) {
          const sortedComments = post.comments.slice().sort((a, b) => new Date(b.metadata.updatedAt) - new Date(a.metadata.updatedAt));

          setComments([...sortedComments]);
        }
      }
    }, [post]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && showPopup) {
          setShowPopup(false);
        }
      };
    
      const handleDocumentClick = (event) => {
        handleClickOutside(event);
      };
    
      document.addEventListener('click', handleDocumentClick);
    
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, [popupRef, showPopup]);
    

    
  
    return (
      <StyledWrapper>
      <StyledContainer isVisible={isVisible}>
        <CircularImage />
        <CylindricalInput
          type="text"
          placeholder="Add a comment..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button onClick={handleAddComment}>Add Comment</Button>
        <Popup ref={popupRef} show={showPopup} top={`${popupPosition.top}px`} right={`${popupPosition.right}px`}>
        <SignUpText onClick={handleSignUpClick}>SignUp to Comment</SignUpText>
      </Popup>
      </StyledContainer>
      <CommentList isVisible={isVisible}>
      {comments.map((commentValue) => (
          commentValue ? (
            <CommentItemComponent
              comment={commentValue}
              key={commentValue.id}
              postId={post.id} 
              dispatch = {dispatch}// Don't forget to add a unique key when mapping through an array
            />
          ) : null
        ))}
        </CommentList>
      </StyledWrapper> 
    );
  };
  
  export default CommentComponent;
