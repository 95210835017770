// NotFoundPage.js
import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const NotFoundText = styled.h2`
  color: #e74c3c;
`;

const NotFoundPage = () => (
  <NotFoundContainer>
    <NotFoundText>404 - Not Found</NotFoundText>
  </NotFoundContainer>
);

export default NotFoundPage;
