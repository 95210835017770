import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust the margin as needed */
`;

export const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    cursor: not-allowed;
    color: #aaa;
  }

  &.active {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
  }
`;