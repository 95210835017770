import {
    FETCH_POSTS_REQUEST,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
    FETCH_POST_FAILURE,
    FETCH_POST_REQUEST,
    FETCH_POST_SUCCESS,
    FETCH_FPOSTS_FAILURE,
    FETCH_FPOSTS_REQUEST,
    FETCH_FPOSTS_SUCCESS
  } from './postActionTypes';
  
  const initialState = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    totalPosts: 0,
    loading: false,
    error: null,
  };
  
  const postsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_POSTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_POSTS_SUCCESS:
        return {
          ...state,
          loading: false,
          posts: action.payload.posts,
          currentPage: action.payload.currentPage || state.currentPage,
          totalPages: action.payload.totalPages || state.totalPages,
          pageSize: action.payload.pageSize || state.pageSize,
          totalPosts: action.payload.totalPosts || state.totalPosts,
        };
      case FETCH_POSTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          currentPage: state.currentPage,
          totalPages: state.totalPages,
          pageSize: state.pageSize,
          totalPosts: state.totalPosts,
        };
      default:
        return state;
    }
  };

  const initialStatePost = {
    post: null,
    loading: false,
    error: null,
  };  

  // Define your reducer function
const postReducer = (state = initialStatePost, action) => {
  switch (action.type) {
    case FETCH_POST_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_POST_SUCCESS:
      return { ...state, loading: false, post: action.payload };
    case FETCH_POST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


// Reducer
const initialFPostState = {
  posts: [],
  loading: false,
  error: null,
};

const FPostReducer = (state = initialFPostState, action) => {
  switch (action.type) {
    case FETCH_FPOSTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_FPOSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload,
      };
    case FETCH_FPOSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // other cases...
    default:
      return state;
  }
};



  
export { postsReducer, postReducer, FPostReducer };
  