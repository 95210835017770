import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { StyledReplyInput, CircularImage, InputWrapper, CylindricalInput , Button, StyledWrapper, ReplyContainer, Popup, SignUpText} from "./replyinput.styled"
import ReplyItem from '../replytext/replytext.component';
import { newReplyApi } from '../../redux/post/comment/reply/replyApiCall';
import { useNavigate } from 'react-router-dom';
import { fetchPostSuccess } from '../../redux/post/postActions';
import { toast } from "react-toastify";



const ReplyInput = ({ isVisible, replies, setCommentState , commentId, postId, dispatch}) => {
    const [replyText, setReplyText] = useState('');
    const [myState, setMyState] = useState(replies);
    const currentUser = useSelector(state => state.user.currentUser); 
    const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef();
    const navigate = useNavigate();




    useEffect(() => {
      setMyState(replies);
    }, [myState, replies]);  

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && showPopup) {
          setShowPopup(false);
        }
      };
    
      const handleDocumentClick = (event) => {
        handleClickOutside(event);
      };

      
    
      document.addEventListener('click', handleDocumentClick);
    
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, [popupRef, showPopup]);

    const handleSignUpClick = () => {
      navigate('/signin');
    };
  
    const handleAddReply = async( event ) => {
      if(currentUser) {
        if (replyText.trim() !== '') {
          
          try {
          const response = await newReplyApi({
            "text": replyText,
            "commentId":commentId,
            "postId": postId
          });
          if (response.status === 201){
            dispatch(fetchPostSuccess(response.data));
            setReplyText('')
          }else{
            
          }
          } catch (error) {
            // Handle error if the API call fails
            toast.info('Error adding reply:');
          }
  
        }
      }else{
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({ top: rect.bottom, right: window.innerWidth - rect.right });
        setShowPopup(!showPopup);
      }
    };
  
    return (
      <StyledWrapper isVisible={isVisible}>
      <StyledReplyInput >
        <CircularImage />
        <InputWrapper>
          <CylindricalInput
            type="text"
            placeholder="Add a reply..."
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <Button onClick={handleAddReply}>Reply</Button>
          <Popup ref={popupRef} show={showPopup} top={`${popupPosition.top}px`} right={`${popupPosition.right}px`}>
        <SignUpText onClick={handleSignUpClick}>SignUp to reply</SignUpText>
      </Popup>
        </InputWrapper>
      </StyledReplyInput>
      {isVisible && <ReplyContainer>{myState.map((reply) => (
            <ReplyItem
              key={reply.id}
              reply={reply}
            />
          ))}</ReplyContainer>}
    </StyledWrapper>
    );
  };
  
  export default ReplyInput;