// postApiCall.js
import api from '../apicall';
import { toast } from "react-toastify";

import { fetchPostsRequest, fetchPostsSuccess, fetchPostsFailure, fetchPostFailure, fetchPostRequest, fetchPostSuccess, fetchFPostsRequest, fetchFPostsSuccess, fetchFPostsFailure } from './postActions';

export const fetchPosts = async (page, pageSize, categoryId) => {
  try {
    const response = await api.get(`/api/posts?page=${page}&pageSize=${pageSize}`,{
      params: {
        categoryId,
        // other query parameters if needed
      }});
    const data = response.data;
    return {
      status: response.status,
      isSuccessfull: true,
      isError: false,
      data,
    };
  } catch (error) {
    toast.info('Error fetching posts:');

    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};

export const fetchPost = async (id) => {
  try {
    const response = await api.get(`/api/posts/${id}`);
    const data = response.data;

    return {
      status: response.status,
      isSuccessfull: true,
      isError: false,
      data,
    };
  } catch (error) {
    toast.info('Error fetching post:');

    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};


export const postLikeApi = async (postId) => {
  try {
    const response = await api.post(`/api/posts/${postId}/like`,{} ,{ withCredentials: true });

    if (response.status === 201) {

      return {
        status: response.status,
        isSuccessfull: true,
        isError: false,
        data: response.data,
      };
    }else{
      return {
          status: 500,
          isSuccessfull: false,
          isError: true,
          data: null,
        };
    }
  } catch (error) {
    // Handle errors, e.g., show an error message to the user

    // Return an error object
    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};




// Function to dispatch actions based on API response
export const fetchPostsAndUpdateStore = async (dispatch, page, pageSize, categoryId) => {
  dispatch(fetchPostsRequest());

  try {
    const response = await fetchPosts(page, pageSize, categoryId);

    if (response.isSuccessfull) {
      dispatch(fetchPostsSuccess(response.data));
    } else {
      dispatch(fetchPostsFailure(response.data));
    }
  } catch (error) {
    dispatch(fetchPostsFailure(error));
  }
};

// Function to dispatch actions based on API response
export const fetchPostAndUpdateStore = async (dispatch, id) => {
  dispatch(fetchPostRequest());

  try {
    const response = await fetchPost(id);
    if (response.isSuccessfull) {
      dispatch(fetchPostSuccess(response.data));
    } else {
      toast.info(response.data)
      dispatch(fetchPostFailure(response.data));
    }
  } catch (error) {
    toast.info(error.message)
    dispatch(fetchPostFailure(error));
  }
};

export const fetchFeaturedPosts = async () => {
  try {
    const response = await api.get(`/api/featuredPost`,{
      params: {
        isFeatured: true,
      },
    });
    const data = response.data;
      
      return {
        status: response.status,
        isSuccessfull: true,
        isError: false,
        data,
      };
  } catch (error) {
    toast.info('Error fetching posts:');

    return {
      status: error.response ? error.response.status : 500,
      isSuccessfull: false,
      isError: true,
      data: null,
    };
  }
};


// Function to dispatch actions based on API response
export const fetchFeaturedPostsAndUpdateStore = async (dispatch) => {
  dispatch(fetchFPostsRequest());

  try {
    const response = await fetchFeaturedPosts();

    if (response.isSuccessfull) {
      dispatch(fetchFPostsSuccess(response.data));
    } else {
      dispatch(fetchFPostsFailure(response.data));
    }
  } catch (error) {
    dispatch(fetchFPostsFailure(error));
  }
};



