import { Container, Image, Text, TextContainer } from './image-text.styled'

export const ImageTextComponent = ({ imageSrc, text, reverse }) => {
    return (
      <Container reverse={reverse}>
        <Image src={imageSrc} alt="Description" />
        <TextContainer>
          <Text>{text}</Text>
        </TextContainer>
      </Container>
    );
  };