import {
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS
} from './categoryActionTypes';

const initialStateCategory = {
  categories: [],
  loading: false,
  error: null,
};

const categoryReducer = (state = initialStateCategory, action) => {

  switch (action.type) {
    case FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // other cases...
    default:
      return state;
  }
};

export default categoryReducer;