import styled from 'styled-components';
import './../../../index.css';

export const SubHeaderDiv = styled.div`
    width: 100%;
    height: auto; /* Changed height to auto to allow content to determine height */
    margin-top: 100px; /* Adjusted margin-top for spacing */

    h1 {
        font-family: 'Signika', sans-serif;
        margin-top: 20px; /* Adjusted margin-top for smaller screens */
        font-size: 86px; /* Decrease font size for smaller screens */
        color: #Fdfcfa;
        text-align: center;
        -webkit-font-smoothing: antialiased;
    }

    p {
        font-family: 'Signika', sans-serif;
        font-size: 22px; /* Decrease font size for smaller screens */
        color: #f0f0f0;
        text-align: center;
    }

    @media (max-width: 768px) {
        margin-top: 40px; /* Further adjust margin-top for smaller screens */

        h1 {
            font-size: 48px; /* Further decrease font size for smaller screens */
        }

        p {
            font-size: 14px; /* Further decrease font size for smaller screens */
        }
    }

    @media (max-width: 480px) {
        margin-top: 20px; /* Further adjust margin-top for very small screens */

        h1 {
            font-size: 34px; /* Further decrease font size for very small screens */
        }

        p {
            font-size: 12px; /* Further decrease font size for very small screens */
        }
    }
`;
