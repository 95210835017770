import React from 'react';
import { BrowserRouter, Routes , Route, Navigate  } from 'react-router-dom';
import About from './pages/about/about.component';
import Home from './pages/home/home.component';
import Blogs from './pages/blogs/blogs.component';
import Contact from './pages/contact/contact.component';
import Products from './pages/product/product.component';
import SignInUp from './pages/sign-in-up/sign-in-up.component';
import { createBrowserHistory } from "history";
import BlogDetail from './pages/blogdetail/blogdetail.component';

const history = createBrowserHistory();

const RoutesPath = () => (
  <BrowserRouter history={history}>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:slug/:id" element={<BlogDetail />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/signin" element={<SignInUp />} />
    </Routes>
  </BrowserRouter>
);
export default RoutesPath;