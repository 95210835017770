// CommonHeader.js
import React from 'react';
import PropTypes from 'prop-types';
import { CommonHeaderDiv } from './commonHeaderDiv.styled';
import Header from '../header/header.component';

const CommonHeader = ({ imageUrl, title }) => (
  <CommonHeaderDiv imageUrl={imageUrl}>
    <Header/>
    <h1 className="title">{title}</h1>
  </CommonHeaderDiv>
);

CommonHeader.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CommonHeader;
