import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};

  flex-direction: column; /* Set the flex direction to column */
  margin-left: 60px;
  
`;


export const StyledReplyInput = styled.div`
  display: flex;
  align-items: left;
  width: 100%;
  margin-top: 10px;
  align-items: left;
  border-bottom: 0.8px solid #ccc; /* Bottom border */
  padding: 10px;
`;

export const CircularImage = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 10px;
`;

export const InputWrapper = styled.div`
  flex: 1; /* Take remaining space */
  display: flex;
  align-items: center;
`;

export const CylindricalInput = styled.input`
  flex: 1; /* Take full width */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  margin-right: 10px;
`;

export const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
`;

export const ReplyContainer = styled.div`
  padding: 10px;
  margin-left: 40px;
  flex: 1; /* Take full available width */
  border-left: 1px solid #ccc;
`;

export const Popup = styled.div`
  position: fixed;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  z-index: 999; /* Adjust the z-index as needed */
  top: ${({ top }) => (top || 0)}; /* Optional: You can adjust the top position */
  right: ${({ right }) => (right || 0)}; /* Optional: You can adjust the right position */
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const SignUpText = styled.span`
  font-size: 12px;
  color: green;
  cursor: pointer;
`;