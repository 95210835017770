import React, { useState, useEffect } from 'react';
import { StyledCommentItem, CircularImage, CommentText, CommentContent, UserName , LikeReplyContainer, ReplyButton, ReplyContainer, LikeContainer, LikeIcon} from "./commentitem.styled"
import ReplyInput from '../replyinput/replyinput.component';
import {commentLikeApi} from '../../redux/post/comment/commentApiCall'
import { useSelector } from 'react-redux';

const CommentItemComponent = ({ comment, postId, dispatch }) => {
    const [showReplies, setShowReplies] = useState(false);
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [myState, setMyState] = useState(comment);
    const currentUser = useSelector(state => state.user.currentUser); 



    useEffect(() => {
      if(currentUser){
        const isCurrentUserLiked = comment.likes.includes(currentUser.userId);
        if (isCurrentUserLiked) {
          setLiked(true)
        } else {
          setLiked(false)
        }
      }
      setLikeCount(comment.likes.length)
      setMyState(comment);
    }, [myState, comment]);
    
  
    const handleReplyClick = () => {
      setShowReplies(!showReplies);
    };

    const handleLikeClick = async () => {
      if(currentUser){
        const response = await commentLikeApi(comment.id);
        if(response.status === 200){
          const isCurrentUserLiked = response.data.likes.includes(currentUser.userId);
          if (isCurrentUserLiked) {
            setLiked(true)
            setLikeCount(likeCount + 1)
          } else {
            setLiked(false)
            setLikeCount(likeCount - 1)
        }
        }
      }
      };

  
    return (
      <StyledCommentItem>
        <CircularImage />
        <CommentContent>
        <UserName>{myState.commenter.name}</UserName>
          <CommentText>{myState.text}</CommentText>
          <LikeReplyContainer>
            <LikeContainer>
                <LikeIcon liked={liked} onClick={handleLikeClick}>
                &#x2665;
                </LikeIcon>
                <span>{likeCount}</span>
            </LikeContainer>
            <ReplyButton onClick={handleReplyClick}>{`Reply (${myState.replies.length})`}</ReplyButton>
          </LikeReplyContainer>
          <ReplyInput
          setCommentState = {setMyState}
          isVisible={showReplies}
          replies={myState.replies}
          commentId={myState.id} 
          postId={postId}
          dispatch = {dispatch}/>
        </CommentContent>
      </StyledCommentItem>
    );
  };
  
  export default CommentItemComponent;