import { toast } from 'react-toastify';
import api from '../../../apicall';



export const newReplyApi = async (jsonObject) => {
    try {
      const response = await api.post('/api/post/comment/reply', jsonObject, { withCredentials: true });
  
      if (response.status === 201) {
  
        return {
          status: response.status,
          isSuccessfull: true,
          isError: false,
          data: response.data,
        };
      }else{
        return {
            status: 500,
            isSuccessfull: false,
            isError: true,
            data: jsonObject,
          };
      }
    } catch (error) {
      // Handle errors, e.g., show an error message to the user
      toast.info('Error during comment:');
  
      // Return an error object
      return {
        status: error.response ? error.response.status : 500,
        isSuccessfull: false,
        isError: true,
        data: jsonObject,
      };
    }
  };


    export const replyLikeApi = async (replyId) => {
        try {
          const response = await api.post(`/api/replies/${replyId}/like`,{} ,{ withCredentials: true });
      
          if (response.status === 200) {
      
            return {
              status: response.status,
              isSuccessfull: true,
              isError: false,
              data: response.data,
            };
          }else{
            return {
                status: 500,
                isSuccessfull: false,
                isError: true,
                data: null,
              };
          }
        } catch (error) {
          // Handle errors, e.g., show an error message to the user
      
          // Return an error object
          return {
            status: error.response ? error.response.status : 500,
            isSuccessfull: false,
            isError: true,
            data: null,
          };
        }
      };

