
import styled from 'styled-components';
export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; /* Set the flex direction to column */
  align-items: center;

`;

export const StyledContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  width: 100%;
  margin-top: 10px;
  border-bottom: 0.8px solid #ccc; /* Bottom border */
  padding: 10px;
`;

export const Popup = styled.div`
  position: fixed;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  z-index: 999; /* Adjust the z-index as needed */
  top: ${({ top }) => (top || 0)}; /* Optional: You can adjust the top position */
  right: ${({ right }) => (right || 0)}; /* Optional: You can adjust the right position */
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const CircularImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 10px;
`;

export const InputWrapper = styled.div`
  flex: 1; /* Take remaining space */
  display: flex;
`;

export const CylindricalInput = styled.input`
  flex: 1; /* Take full width */
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 8px;
  margin-right: 10px;
`;

export const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 8px 16px;
  cursor: pointer;
`;

export const CommentList = styled.ul`
  display: ${({ isVisible }) => (isVisible ? 'inline' : 'none')};
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 10px;
`;

export const CommentItem = styled.li`
  margin-bottom: 5px;
`;

export const SignUpText = styled.span`
  color: green;
  cursor: pointer;
`;
