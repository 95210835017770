import React , { useState }from 'react';
import { SignUpContainer, SignUpTitle, SignUpForm, SignUpButton  } from './sign-up.styled';
import { useDispatch } from 'react-redux';
import { signUpUser } from '../../redux/user/userApiCall';
import { useNavigate } from 'react-router-dom';
import { fetchCurrentUser } from '../../redux/user/userApiCall';
import { toast } from "react-toastify";




const SignUp = () => {

    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const validatePassword = (password) => {
        // Implement your password validation logic
        // Return an error message if validation fails, otherwise, return an empty string
        // Example: Password must be at least 6 characters long
        return password.length < 6 ? 'Password must be at least 6 characters long' : '';
      };
      
      const validateConfirmPassword = (password, confirmPassword) => {
        // Implement your confirm password validation logic
        // Return an error message if validation fails, otherwise, return an empty string
        // Example: Passwords do not match
        return password !== confirmPassword ? 'Passwords do not match' : '';
      };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'password') {
            setPasswordError(validatePassword(value));
        } else if (name === 'confirmPassword') {
            setConfirmPasswordError(validateConfirmPassword(formData.password, value));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if((validateConfirmPassword(formData.password, formData.confirmPassword) === '') && formData.password.length > 5){
        // Call the signUpUser function and get the response
        const response = await signUpUser(formData);
        // Handle the response as needed
            if (response && response.status === 201) {
            // Successful signup, you can perform additional actions if needed
                await dispatch(fetchCurrentUser());
                // Redirect to the home page
                navigate('/');
            } else {
            // Handle error, show an error message, etc.
            toast.info('Error during signup:');
            }
        }else{
            toast.info('Validation failed, please fill data properly');
        }
    };

    return(<SignUpContainer>
        <SignUpTitle>Sign Up!</SignUpTitle>
        <h6>Welcome to our family sign up and get started</h6>

        <SignUpForm onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Enter your name"  value={formData.name} onChange={handleChange} required/>
            <input type="email" name="email" placeholder="Enter email address"  value={formData.email} onChange={handleChange} required/>
            <input type="password" name="password"placeholder="Enter your password" value={formData.password} onChange={handleChange} required/>
            {passwordError && <span className="error">{passwordError}</span>}
            <input type="password" name="confirmPassword"placeholder="confirm your password" value={formData.confirmPassword} onChange={handleChange} required/>
            {confirmPasswordError && <span className="error">{confirmPasswordError}</span>}
            <SignUpButton type="submit" className="hero-btn red-btn">SIGN UP</SignUpButton>
        </SignUpForm>
    </SignUpContainer>
    );
}

export default SignUp;

