import React, { useState } from 'react';
import { SignInContainer, SignInTitle, SignInForm, SignInButton } from './sign-in.styled';
import { useDispatch } from 'react-redux';
import { signInUser } from '../../redux/user/userApiCall';
import { setCurrentUser } from '../../redux/user/userActions';
import { fetchCurrentUser } from '../../redux/user/userApiCall';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";



const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Call the signInUser function and get the response
    const response = await signInUser(formData);
  
    // Handle the response as needed
    if (response && response.isSuccessfull) {
      // Fetch current user first
      await dispatch(fetchCurrentUser());
      // Update user in the store
      navigate('/');
    } else {
      // Handle error, show an error message, etc.
      toast.info('Error during signin:');
    }
  };

  return (
    <SignInContainer>
      <SignInTitle>Welcome Back!</SignInTitle>
      <h6>Sign in with your email and password</h6>

      <SignInForm onSubmit={handleSubmit}>
        <input type="email" name="email" placeholder="Enter email address" value={formData.email} onChange={handleChange} required />
        <input type="password" name="password" placeholder="Enter your password" value={formData.password} onChange={handleChange} required />
        <SignInButton type="submit" className="hero-btn red-btn">
          SIGN IN
        </SignInButton>
      </SignInForm>
    </SignInContainer>
  );
};

export default SignIn;
