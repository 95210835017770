import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 80vw; /* Update max-width to take 80% of the viewport width */
  margin: 40px auto; /* Adjust margin as needed */
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
`;

export const Image = styled.img`
  width: 20%; /* Set width to 20% of the container */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  border-radius: 8px; /* Add border-radius for rounded corners if desired */
  flex-grow: 0; /* Prevent the image from growing beyond its specified width */
  align-self: flex-start; /* Align the image to the top of its container */
`;

export const TextContainer = styled.div`
  max-width: 70%; /* Adjust text container width as needed */
  padding: 20px; /* Add padding around text as needed */
  border-radius: 8px; /* Add border-radius for rounded corners if desired */
  background-color: #f7f5f6; /* Add background color */
`;

export const Text = styled.div`
  font-size: 18px; /* Adjust font size as needed */
  line-height: 1.6; /* Adjust line height as needed */
  color: #39474f; /* Adjust text color as needed */
`;