// blogcommentandlike.styled.js

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; /* Set the flex direction to column */
  align-items: center;

  
`;

export const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  height: 35px;
  border-top: 0.8px solid #ccc; /* Top border */
  border-bottom: 0.8px solid #ccc; /* Bottom border */
`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LikeIcon = styled.span`
  margin-right: 5px;
  cursor: pointer;
  color: ${({ liked }) => (liked ? 'red' : 'black')};
`;

export const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;  
  margin-left: 20px;
  cursor: pointer;
`;

export const ThreeDots = styled.span`
  cursor: pointer;
  margin-left: auto; /* This will push it to the right */
`;

export const CommentIcon = styled.span`
  margin-right: 5px;
  cursor: pointer;
  color: black; /* Set the color for the comment icon */
`;

export const Popup = styled.div`
  position: fixed;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  z-index: 999; /* Adjust the z-index as needed */
  top: ${({ top }) => (top || 0)}; /* Optional: You can adjust the top position */
  right: ${({ right }) => (right || 0)}; /* Optional: You can adjust the right position */
  display: ${({ show }) => (show ? 'block' : 'none')};
`;


export const ReportText = styled.span`
  color: red;
  cursor: pointer;
`;
