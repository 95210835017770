import RoutesPath from './Routes'
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from './redux/user/userApiCall';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './redux/store'; 
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-ZYW8794Z41";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
}, [])

  useEffect( () => {
     dispatch(fetchCurrentUser());
  }, [dispatch]);


  return (
    <Provider store={store}>
         <RoutesPath />
         <ToastContainer />
         </Provider>
  );

}

export default App;
