import React from "react";
import { BlogCardRightContainer ,BlogImage, BlogCardContent, BlogTitle, BlogMeta, AuthorName, BlogDescription} from "./blogcardright.styled";

const BlogCardRight = ({ title, imageUrl, publishedDate, authorName, description }) => {
    const createMarkup = () => ({ __html: description });
  
    return (
      <BlogCardRightContainer>
        <BlogImage src={imageUrl} alt="Blog Image" />
        <BlogCardContent>
          <BlogTitle>{title}</BlogTitle>
          <BlogMeta>
            <div>Published on {publishedDate}</div>
            <AuthorName>By {authorName}</AuthorName>
          </BlogMeta>
          <BlogDescription dangerouslySetInnerHTML={createMarkup()} />
        </BlogCardContent>
      </BlogCardRightContainer>
    );
  };
  
  export default BlogCardRight;
  