import {
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS
} from './categoryActionTypes';

export const fetchCaregoryRequest = () => ({
  type: FETCH_CATEGORY_REQUEST,
});

export const fetchCaregorySuccess = (data) => ({
  type: FETCH_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchCaregoryFailure = (error) => ({
  type: FETCH_CATEGORY_FAILURE,
  payload: error.message,
});

