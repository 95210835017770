// CommentItem.js

import styled from 'styled-components';

export const StyledCommentItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: stretch;
  width: 100%;

  @media (max-width: 801px) {
    width: 50%;
    }

    @media (max-width: 480px) {
        width: 50%;
    }
  

`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

export const LikeIcon = styled.span`
  margin-right: 5px;
  cursor: pointer;
  color: ${({ liked }) => (liked ? 'red' : 'black')};
`;

export const CircularImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 10px;
`;

export const CommentContent = styled.div`
  flex: 1;
`;

export const CommentText = styled.p`
  margin-bottom: 5px;
`;

export const UserName = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

export const LikeReplyContainer = styled.div`
  display: flex;
  align-items: center;
`;


export const ReplyButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;



