import React from "react";
import { SubHeaderDiv } from "./sub-header.styled"

const SubHeader = () => {
    return (
        <SubHeaderDiv>
            <h1>MyAppSack</h1>
            <p>"We are a digital product company"</p>
        </SubHeaderDiv>
    )
};

export default SubHeader;